
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.stock.add_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addStock" autocomplete="off">
                    <v-layout row wrap>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="stock.stock_date" type="date" :label="$store.getters.language.data.stock.stock_date" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="stock.stock_invoice_number" :label="$store.getters.language.data.stock.stock_invoice_number" dense
                                class="mx-1" filled outlined>
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="stock_move_types" v-model="stock.stock_move_type_id" dense  filled outlined item-text="stock_move_type_name"
                                item-value="stock_move_type_id" :return-object="false" :label="$store.getters.language.data.stock.stock_move_type_name">
                            </v-select>
                        </v-flex>
                        <v-flex xs12 lg1 xl1 md3 sm4>
                            <v-btn color="warning" to="/stock-list/edit"> <v-icon> mdi-pencil-outline </v-icon> </v-btn>
                        </v-flex>
                        <v-flex xs12 lg1 xl1 md3 sm4>
                            <v-btn color="success" @click="addItemToList()"> <v-icon> mdi-plus </v-icon> </v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <!-- <v-data-table :headers="headers"   v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="stock_id">
                    <template v-slot:[`item.stock_price`]="{ item }">
                        <div>
                            {{ (item.stock_price).toLocaleString() }}
                            {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
                        </div>
                    </template>

                    <template v-slot:[`item.stock_date`]="{ item }">
                        <div>
                            {{ new Date(item.stock_date).toISOString().split('T')[0] }}
                        </div>
                    </template>

                    <template v-slot:[`item.stock_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/stock-list/'+item.stock_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectStock(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table> -->
                
                <v-data-table :headers="headers" :search="search" :items="rows" class="elevation-0">
                    <template v-slot:item="{item}">
                        <tr>
                            <td> 
                                <v-autocomplete dense v-model="item.item_id" :items="items" item-text="item_name" item-value="item_id"></v-autocomplete> 
                            </td>
                            <td> 
                                <v-text-field type="number" dense v-model="item.stock_qty"></v-text-field> 
                            </td>
                            <td> 
                                <v-text-field type="number" dense v-model="item.stock_price"></v-text-field> 
                            </td>
                            <td> 
                                <v-textarea rows="1" auto-grow dense v-model="item.stock_note"></v-textarea> 
                            </td>
                            <td>
                                <v-btn color="error" icon class="mx-1" @click="selectStock(item)" >
                                    <v-icon> mdi-delete-outline </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions >
                <v-btn  color="primary" class="float-end" :loading="loading_btn" @click="addStockList()">{{$store.getters.language.data.stock.add_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.stock.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.stock.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteStock()">
                {{$store.getters.language.data.stock.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from '../../requests/stock.request.js'
    export default {
        data() {
            return {
                stock: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_stock : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.stock.item_name,
                        align: 'start',
                        sortable: true,
                        value: 'item_name',
                    },
                    { 
                        text: this.$store.getters.language.data.stock.stock_qty,
                        align: 'start',
                        sortable: true,
                        value: 'stock_qty',
                    },
                    { 
                        text: this.$store.getters.language.data.stock.stock_price,
                        align: 'start',
                        sortable: true,
                        value: 'stock_price',
                    },
                    { 
                        text: this.$store.getters.language.data.stock.stock_note,
                        align: 'start',
                        sortable: true,
                        value: 'stock_note',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'stock_id',
                    }
                ],
            }
        },
        computed: {
            items(){
                return this.$store.getters.items_list
            },
            
            stock_move_types(){
                return this.$store.getters.stock_move_types_list
            },
        },
        mounted(){
            // this.readStock();
        },
        methods: {
            addStockList() {
                if(this.rows.length > 0) {
                    this.loading_btn = true;
                    this.rows = this.rows.filter(obj => obj.item_id)
                    this.rows = this.rows.map(obj => {
                        return {
                            ...obj,
                            stock_move_type_id : this.stock.stock_move_type_id,
                            stock_date : this.stock.stock_date,
                            stock_invoice_number : this.stock.stock_invoice_number
                        }
                    })
                    requests.createStockList({list: this.rows}).then(r => {
                        if (r.status == 200) {
                            this.stock = {}
                            this.rows = []
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.add_success,
                                color: 'success'
                            }
                        } else {
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.failed,
                                color: 'error'
                            }
                        }
                    }).finally(() => this.loading_btn = false)
                } else {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.fill_information_correctly,
                        color: 'warning'
                    }
                }
            },
            addItemToList() {
                this.rows.push({
                    item_id: null,
                    stock_qty: 0,
                    stock_price: 0,
                    stock_note: null,
                })
            },
            deleteStock() {
                this.rows = this.rows.filter(obj => obj !== this.selected_stock)
                this.delete_dialog = false
            },
            selectStock(i) {
                this.selected_stock = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    